<template>
  <div class="page">
    <Navbar title="验证明细" type="SMP" :callback="back" />
    <van-notice-bar
      left-icon="volume-o"
      text="请填写真实有效的个人信息，如信息造假则自行承担分险!"
    />
    <div v-for="match in matchList" :key="match.key">
      <div class="demand color-m" v-if="match.result === 'Y'">
        <van-row class="title">
          <van-col span="20"
            >{{ match.name }}：{{ match.content }}
            <van-tag :color="COLOR_M"
              ><van-icon name="passed" /> 符合条件</van-tag
            ></van-col
          >
          <van-col span="4"
            ><van-tag plain :color="COLOR_M"
              ><van-icon name="edit" @click="edit(match.group)" /> 修改</van-tag
            ></van-col
          >
        </van-row>
        <van-row class="condition">
          <van-col span="24"
            >条件要求：<span v-if="match.mode === 'UMT'">无限制</span>
            <span v-else-if="match.mode === 'NTN'">不为空</span>
            <span v-else>{{ match.condition }}</span></van-col
          >
        </van-row>
      </div>
      <div
        class="demand color-s1"
        v-if="
          match.result === 'N' && (match.mode === 'NTN' || match.mode === 'REQ')
        "
      >
        <van-row class="title">
          <van-col span="20"
            >{{ match.name }}：{{ match.content }}
            <van-tag type="primary" :color="COLOR_S1"
              ><van-icon name="close" /> 不符合要求条件</van-tag
            ></van-col
          >
          <van-col span="4">
            <van-tag plain :color="COLOR_S1"
              ><van-icon name="edit" @click="edit(match.group)" /> 修改</van-tag
            >
          </van-col>
        </van-row>
        <van-row class="condition">
          <van-col span="24"
            >条件要求：<span v-if="match.mode === 'NTN'">不为空</span
            ><span v-else>{{ match.condition }}</span></van-col
          >
        </van-row>
      </div>
      <div
        class="demand color-s2"
        v-if="match.result === 'N' && match.mode === 'MDR'"
      >
        <van-row class="title">
          <van-col span="20"
            >{{ match.name }}：{{ match.content }}
            <van-tag type="primary" :color="COLOR_S2"
              ><van-icon name="close" /> 不符合强制条件</van-tag
            ></van-col
          >
          <van-col span="4"
            ><van-tag plain :color="COLOR_S2"
              ><van-icon name="edit" @click="edit(match.group)" /> 修改</van-tag
            ></van-col
          >
        </van-row>
        <van-row class="condition">
          <van-col span="24">条件要求：{{ match.condition }}</van-col>
        </van-row>
      </div>
    </div>
    <div v-if="matchList.length <= 0" class="norecord">
      <van-icon name="info-o" /> 任务未设置条件
    </div>
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import { Tag, Icon, NoticeBar } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    [Tag.name]: Tag,
    [Icon.name]: Icon,
    [NoticeBar.name]: NoticeBar
  },
  data () {
    return {
      personCode: '',
      taskCode: '',
      matchList: [],
      loadingShow: false
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.taskCode = query.taskCode
    this.retrieveMatchList()
  },
  methods: {
    async retrieveMatchList () {
      this.loadingShow = true
      var pd = { personCode: this.personCode, taskCode: this.taskCode }
      const { data: res } = await this.$http.post(this.BMS_URL + '/member/person/retrievePersonMatchDetail', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.matchList = res.data
      }
      this.loadingShow = false
    },
    edit (group) {
      if (group === 'BAS') {
        this.$router.push({ path: '/mde/people/person/dtlBase', query: { personCode: this.personCode } })
      }
    },
    back () {
      var url = window.sessionStorage.getItem('person_match_detail_back')
      window.location.href = url
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  ::v-deep .van-notice-bar {
    font-size: 14px;
  }
  .demand {
    margin: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    text-align: left;
    .title {
      padding-left: 10px;
      font-weight: 600;
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #eee;
      .result {
        text-align: center;
      }
    }
    .condition {
      padding: 10px 10px;
      line-height: 20px;
    }
  }
  .norecord {
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
  }
}
</style>
